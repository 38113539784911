import { Controller } from "stimulus"
import TomSelect from "tom-select";
import { regexFromFormat } from '../components/regex_from_format'
export default class extends Controller {
  static targets = ["tomSelect", "tomSelectAutoComplete", "tomSelectStyle"]

  connect() {
    this.tomSelectTargets.forEach((select)=>{
      new TomSelect(select, {
        controlInput: null
      })
    })
    this.tomSelectAutoCompleteTargets.forEach((select)=>{
      new TomSelect(select, {
        plugins: {
          'clear_button': {
            'title': 'Supprimer le titre',
            'html': function (data) {
              return `<div class="${data.className}" title="${data.title}"><svg width="11" height="11" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.90301 1.03737C2.38782 0.522179 1.55253 0.522179 1.03734 1.03737C0.522146 1.55256 0.522146 2.38785 1.03734 2.90304L6.63435 8.50005L1.03739 14.097C0.522199 14.6122 0.522199 15.4475 1.03739 15.9627C1.55258 16.4779 2.38787 16.4779 2.90306 15.9627L8.50002 10.3657L14.097 15.9627C14.6122 16.4779 15.4475 16.4779 15.9627 15.9627C16.4779 15.4476 16.4779 14.6123 15.9627 14.0971L10.3657 8.50005L15.9628 2.90298C16.478 2.38779 16.478 1.5525 15.9628 1.03731C15.4476 0.522118 14.6123 0.522118 14.0971 1.03731L8.50002 6.63438L2.90301 1.03737Z" fill="#D1293D"/> </svg> </div>`;
            }
          }
        },
        create: true,
        persist: false,
        addPrecedence: true,
        render: {
          option_create: function (data, escape) {
            return '<div class="create">Rechercher <strong>' + escape(data.input) + '</strong>&hellip;</div>';
          },
          no_results: function (data, escape) {
            return '<div class="no-results">Aucun résultat</div>';
          }
        }
      })
    })
    this.tomSelectStyleTargets.forEach((select)=>{
      new TomSelect(select, {
        controlInput: null,
        allowEmptyOption: true,
        create: false
      })
    })
  }


}

