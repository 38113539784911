import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["list"]

  connect() {
    this.listTarget.scrollTo({
              top: this.listTarget.scrollHeight,
              behavior: 'smooth'
        });
  }
}
