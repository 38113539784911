import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'submitBtn', 'category', 'condition', 'conditionOption', 'tableBody', 'table', 'row', 'price', 'form', 'price', 'estimatedPrice', 'modalBtn'];

  request() {
    this.modalBtnTarget.disabled = true;
    this.priceTarget.classList.add('d-none');
    const check = this.checkInput();
    if(check == true) {
      this.modalBtnTarget.disabled = false;
      this.send_price_request();
      this.priceTarget.classList.remove('d-none');
    }
  }

  send_price_request() {
    const category = this.categoryTarget.value;
    const price = this.estimatedPriceTarget.value;
    const condition = this.conditionTarget.value;
    fetch( `/customer/purchases/${this.formTarget.dataset.purchase}/no_bar_code/?category=${category}&estimated_price=${price}&condition=${condition}`)
    .then( response => response.json() )
    .then( response => {
        this.priceTarget.innerHTML = `Prix de reprise proposé : <span class="text-orange">${response}€</span>`
    } );
  }

  checkInput() {
    const inputs = this.inputTargets
    const isNotEmpty = (input) => input.value != "";
    if(inputs.length > 0 && inputs.every(isNotEmpty)) {
      return true
    } else {
      return false
    }

  }

  // updateCategory() {
  //   this.updateConditions();
  //   this.updateTable();
  // }

  updateConditions = () => {
    this.conditionTarget.value = "";
    const value = this.categoryTarget.value;
    this.conditionOptionTargets.forEach(option => {
      option.disabled = false;
      option.classList.remove('d-none');
      if(option.dataset.category != value) {
        option.disabled = true;
        option.classList.add('d-none');
      }
    });
  }

  updateTable = () => {
    this.tableTarget.classList.remove("d-none");
    this.rowTargets.forEach((row) => {row.remove()});
    const array = this.conditionOptionTargets.reverse();
    array.forEach((option) => {
      if(option.disabled == false){
        this.tableBodyTarget.insertAdjacentHTML('afterbegin',
          `<tr data-no-bar-code-target="row"><td>${option.innerHTML}</td><td>${option.dataset.description}</td><tr>`
        )
      }
    })
  }

  submitForm = () => {
    this.formTarget.submit();
  }

}
