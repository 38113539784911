import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["form", "startDate", "endDate"]

  connect() {
    this.startDateTarget.addEventListener("change", this.handleChange.bind(this));
    this.endDateTarget.addEventListener("change", this.handleChange.bind(this));
  }

  reset_dates() {
    this.startDateTarget.value = "";
    this.endDateTarget.value = "";
    this.submit_form();
  }

  submit_form() {
    const submitButton = this.formTarget.querySelector('input[type="submit"]');
    submitButton.click();
  }

  handleChange() {
    if (this.startDateTarget.value && this.endDateTarget.value) {
      this.submit_form();
    }
  }
}
