const disableAutocomplete = () => {
  const inputs = document.querySelectorAll('input')

  if (inputs !== []) {
    inputs.forEach((input) => {
      if (!['password', 'hidden', 'submit', 'number', 'checkbox'].includes(input.type)) {
        if (!(input.dataset.autocomplete == "true")) {
          input.type = 'search'
          input.autocomplete = 'off'
        }
      }
    })
  }

}

export { disableAutocomplete }
