import { submitForm } from "./submit_form"

const initSellerCode = () => {
  const form = document.getElementById("seller-code")
  if(form){
    const inputs = form.querySelectorAll('input[type="password"]')
    inputs.forEach((input) => {
      input.addEventListener("keyup", (a) => {
        if (input.value.length == "1") {
          if(input["name"] == "code_4"){
            input.form.submit()
          } else {
            input.nextSibling.nextElementSibling.focus();
          }
        }
      })
    })
  }
}

export { initSellerCode };
