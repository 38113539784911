import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
// External imports
import 'bootstrap';

// Internal imports, e.g:
import "controllers"
// import { initSelect2 } from '../components/init_select2';
import { listenInputs } from "../components/radio_buttons"
import { initFlatpickr, initFlatpickrNoTime, initFlatpickrNoTimeFr } from "../components/flatpicker"
// import { listenOptin } from "../components/optin"
// import { btnChrome_onclick } from "../components/fullscreen"
import { listenSearchbar } from "../components/navbar"
import { submitBasketInput } from "../components/basket_ean_input"
import { submitReceivedBooksInput } from "../components/received_products_ean_input"
import { initSellerCode } from "../components/seller_code"
import { initRadioInputs } from "../components/survey_radio_input"
import { redirect } from "../components/redirect"
import { disableAutocomplete } from "../components/disable_autocomplete"
import { hideKeyboard } from "../components/hide_keyboard"
import { emailToLowerCase } from "../components/customer_email_to_lower_case"
import {dateFormatting} from "../components/cleave";
import {popUpEmailInformation} from "../components/popup";
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

document.addEventListener('turbolinks:load', () => {
  listenInputs();
  initFlatpickr();
  initFlatpickrNoTime();
  initFlatpickrNoTimeFr();
  // listenOptin();
  listenSearchbar();
  // btnChrome_onclick();
  submitBasketInput();
  submitReceivedBooksInput();
  initSellerCode();
  initRadioInputs();
  redirect();
  disableAutocomplete();
  hideKeyboard();
  emailToLowerCase();
  dateFormatting();
  popUpEmailInformation();

  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');

    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});
