import Cleave from 'cleave.js';

const dateFormatting = () => {
    const deliverDate = document.querySelector('.deliver-date')
    if(deliverDate) {
        new Cleave('.deliver-date', {
            date: true,
            datePattern: ['d', 'm', 'Y']
        });
    }
};

export { dateFormatting };