const submitForm = (input) => {
  input.addEventListener('input', (e) => {
    const regex12 = /^(\d{12}){1}$/g;
    const regex12_valid = input.value.match(regex12);

    const regex = /^(\d{13}){1}$/g;
    const valid = input.value.match(regex);
    if(valid !== null && valid.length == 1) {
      input.form.submit()
    }
    else if (regex12_valid !== null && regex12_valid.length == 1) {
      submit0nFetchProduct(input.value, input.dataset.url, input);
    }
  })
}

const submit0nFetchProduct = (ean, url, input) => {
  console.log(url)
  fetch(url + "?" + new URLSearchParams({
      ean: ean
    }), {
    method: "GET",
    headers: { "Accept": "application/json" }
  })
  .then(response => response.json())
  .then((data) => {
    if (data.id != "") {
      input.value = data.ean
      input.form.submit()
    }
  })
}

export { submitForm };
