import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const flash = this.element
    setTimeout(function () {
      flash.style.display = 'none';
    }, 5000);
  }
}
