import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["modal"];

  static values = {
    open: String,
    url: String
  }

  connect() {
    if(this.openValue === "true") {
      $(this.modalTarget).modal('show');
    }
  }

  checkValidity(e) {
    e.preventDefault()
    const location = e.currentTarget.href;
    fetch(this.urlValue)
      .then(response => response.json())
      .then((data) => {
        if(data.validity) {
          window.location = location
        } else {
          $(this.modalTarget).modal('show');
        }
      })
  }
}
