const emailToLowerCase = () => {
    const emails = document.querySelectorAll('#customer_email')
    if(emails != []) {
        emails.forEach((email) => {
            email.addEventListener("keyup", (e) => {
                email.value = email.value.toLowerCase();
            });
        })
    }
};

export {emailToLowerCase};