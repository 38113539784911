
const hideKeyboard = () => {
    const el = document.getElementById('product_ean');
    const att = document.createAttribute("readonly");
    if (el) {
        el.setAttributeNode(att); // Force keyboard to hide on input field.
        setTimeout(() => {
            el.blur(); //close the keyboard
            el.focus(); //focus on the input
            // Remove readonly attribute after keyboard is hidden.
            el.removeAttribute('readonly');
        }, 100);
    }
}

export {hideKeyboard}
