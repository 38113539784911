
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link", "btnExpand", "navbar"]

  hideNavbar() {
    if (!this.navbarTarget.classList.contains("navbar-logistic--hidden")) {
      this.navbarTarget.classList.toggle("navbar-logistic--hidden");
      this.btnExpandTargets.forEach(btn => {
        btn.classList.toggle("d-none");
      });
    }
  }
  showNavbar() {
    if (this.navbarTarget.classList.contains("navbar-logistic--hidden")) {
      this.navbarTarget.classList.toggle("navbar-logistic--hidden");
      this.btnExpandTargets.forEach(btn => {
        btn.classList.toggle("d-none");
      });
    }
  }

  connect () {
    if (window.screen.width <= 1280) {
      this.navbarTarget.classList.add("fixed")
      this.hideNavbar()
    }
    window.addEventListener('resize', () => {
      if (window.screen.width <= 1280) {
        this.navbarTarget.classList.add("fixed")
        this.hideNavbar()
      } else {
        this.navbarTarget.classList.remove("fixed")
        this.showNavbar()
      }
    });

  }
  activeLink(event) {
    this.linkTargets.forEach((link) => {
      link.classList.remove("active")
    })
    event.currentTarget.classList.add("active")
  }
  expand() {
    this.navbarTarget.classList.toggle("navbar-logistic--hidden");
    this.btnExpandTargets.forEach(btn => {
      btn.classList.toggle("d-none");
    });
  }
}
