import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["input", "modal"]

  connect() {
    var controller = this
    $('.modalInputAutofocus').on('shown.bs.modal', function (event) {
      const input = event.currentTarget.querySelector('[data-modal-input-autofocus-target="input"]')
      controller.#focusInput(input);
    })
  }

  #focusInput(input) {
    input.readOnly = true;
    input.focus()
    setTimeout(function(){input.readOnly = false;}, 50);
  }
}
