import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ['container', "input"];

  send(event) {
  // [...]

    fetch(this.formTarget.action, {
      method: "POST",
      headers: { "Accept": "application/json" },
      body: new FormData(this.formTarget)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.inserted_item) {
          this.itemsTarget.insertAdjacentHTML("beforeend", data.inserted_item)
        }
        this.formTarget.outerHTML = data.form
      })
  }

  get(event) {
    let url = this.inputTarget.dataset.url
    const value = this.inputTarget.value
    url = url.replace("ID_TO_CHANGE", `${this.inputTarget.value}`);
    fetch(url, {
      method: "GET",
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then((data) => {
        this.containerTarget.outerHTML = data.partial;
    })
  }

}
