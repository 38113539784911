import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['locationName', 'locationForm'];

  connect() {
  }

  toggle_form() {
    this.locationFormTarget.classList.toggle('d-none');
    this.locationNameTarget.classList.toggle('d-none');
  }
}
