import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.#focusInput(this.inputTarget);
    if (this.inputTarget.dataset.unlockFocus == "true") {
      document.addEventListener("click", (e)=>{
        if (document.activeElement != this.inputTarget && document.activeElement.dataset.keepFocus != "true") {
          this.#focusInput(this.inputTarget);
        }
      })
    }
  }

  #focusInput(input) {
    input.readOnly = true;
    input.focus()
    setTimeout(function(){input.readOnly = false;}, 50);
  }
}
