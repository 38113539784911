import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

const initFlatpickr = () => {
  flatpickr(".datepicker", {
    enableTime: true,
    "locale": French
  });
}
const initFlatpickrNoTime = () => {
  flatpickr(".datepicker_no_time", {
    "locale": French
  });
}
const initFlatpickrNoTimeFr = () => {
  flatpickr(".datepicker_no_time_fr", {
    "locale": French,
    dateFormat: "d/m/Y",
  });
}
export { initFlatpickr, initFlatpickrNoTime, initFlatpickrNoTimeFr };
