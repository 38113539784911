import { Controller } from "stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ['input', 'dropdown']

  connect() {
    new TomSelect(this.inputTarget, {
      create: true,
      sortField: {
        field: "text",
        direction: "asc"
      },
      dropdownParent: ".switch-lot-list",
      maxOptions: 5,
      render: {
        option_create: function (data, escape) {
          return '<div class="create">Créer un nouveau lot <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: function (data, escape) {
          return '<div class="no-results">Aucun résultat</div>';
        }
      }
    });
    this.dropdownTarget.querySelector(".ts-dropdown").removeAttribute("style");
  }

}
