const initRadioInputs = () => {
  const survey_radios = document.querySelectorAll('.survey_radio label');
  survey_radios.forEach((radio) => {
    radio.addEventListener('click', (e) => {  
      survey_radios.forEach((radio) => { radio.classList.remove('emoji-selected')});
      survey_radios.forEach((radio) => { radio.classList.add('opacity-half')});
      e.currentTarget.parentNode.querySelector('.survey_radio label').classList.add('emoji-selected');
      e.currentTarget.parentNode.querySelector('.survey_radio label').classList.remove('opacity-half');
    });
  });
};

export {initRadioInputs};
