import { Controller } from "stimulus";
// import TomSelect from "tom-select";
// import { update_condition } from '../components/condition_updater'

export default class extends Controller {
  static targets = ['form', 'input', 'conditionField', 'productList']

    connect() {
      // if (this.conditionFieldTargets.length > 0) {
      //   this.refreshConditionFields(this.conditionFieldTargets)
      // }
    }

  // refreshConditionFields(conditionFields) {
  //   conditionFields.forEach((conditionField) => {
  //     new TomSelect(conditionField, {
  //       controlInput: null
  //     })
  //     update_condition(conditionField)
  //   })
  // }

  updateProductCounter() {
    const productsCountField = document.getElementById('products-count')
    productsCountField.innerHTML = this.conditionFieldTargets.length
  }

  // update_condition(event) {
  //   const selectField = event.target
  //   update_condition(selectField)

  //   const csrfToken = document.querySelector('meta[name="csrf-token"]').content
  //   const productId = event.currentTarget.dataset.productId;
  //   const currentProductForm = document.querySelector("#edit_received_product_" + productId)
  //   const updateReceivedProductUrl = currentProductForm.action
  //   const newConditionValue = event.currentTarget.value

  //   fetch(updateReceivedProductUrl, {
  //     method: "PUT",
  //     headers: {
  //       "X-CSRF-Token": csrfToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     },
  //     body: JSON.stringify({
  //       received_product: {
  //         condition_id: newConditionValue,
  //       },
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then((data) => {
  //       this.productListTarget.innerHTML = data.partial_html
  //       this.refreshConditionFields(this.conditionFieldTargets)
  //       this.updateProductCounter()
  //     })
  // }

  remove_received_product(event) {
    event.preventDefault()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    const productId = event.currentTarget.dataset.productId;
    const currentProductForm = document.querySelector("#edit_received_product_" + productId)
    const removeReceivedProductUrl = currentProductForm.action

    fetch(removeReceivedProductUrl, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        received_product: {
          storage_location_id: null,
        },
      }),
    })
      .then(response => response.json())
      .then((data) => {
        this.productListTarget.innerHTML = data.partial_html
        // this.refreshConditionFields(this.conditionFieldTargets)
        this.updateProductCounter()
      })
  }

  send_received_product(event) {
    event.preventDefault()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    const url = this.formTarget.action + "/?sku=" + this.inputTarget.value
    const storageLocationId = this.formTarget.dataset.storageLocation

      fetch(url, {
          method: "GET",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          Accept: "application/json"
        },
      })
        .then(response => response.json())
        .then((data) => {
          if (data.message) {
            console.log("sku invalid")
            document.querySelector("#flash").outerHTML = data.message
          } else {
          const product_id = data.id
          const updateReceivedProductUrl = `/logistic/storage_locations/${storageLocationId}/received_products/${product_id}`

            return fetch(updateReceivedProductUrl, {
              method: "PUT",
              headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json"
              },
              body: JSON.stringify({
                received_product: {
                  storage_location_id: storageLocationId,
                },
              }),
            })
            .then(newResponse => newResponse.json())
            .then((newData) => {
              this.productListTarget.innerHTML = newData.partial_html
              // this.refreshConditionFields(this.conditionFieldTargets)
              this.updateProductCounter()
              this.inputTarget.value = ""
            })
          }
        })
  }
}
