import { submitForm } from "./submit_form"

const submitBasketInput = () => {
  const inputBasket = document.getElementById("product_ean");
  if(inputBasket){
    inputBasket.addEventListener('keydown', function (e) {
      if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
        e.preventDefault();
      }
    }, true);
    submitForm(inputBasket);
  }
}

export { submitBasketInput };
