const redirect = () => {
  const endJourney = document.getElementById("end-journey")
  if(endJourney) {
    setTimeout(() => {
      window.location.href = "/terminal"
    }, 6000);
  }
}

export { redirect }
