import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["btn"]


  changeDisabled() {
    this.btnTarget.disabled = true
  }
}
