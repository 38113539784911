const hideNavbar = (navbar) => {
    navbar.style.display = "none";
};

const showNavbar = (navbar) => {
    navbar.style.display = "block";
};

const listenSearchbar = () => {
    const searchbar = document.querySelector('input.searchInput');
    const navbar = document.querySelector('.navbar-log');
    if (searchbar && window.innerWidth < 400 ) {
        if ( searchbar.autofocus ) { hideNavbar(navbar) } ;
        searchbar.addEventListener('focus', () => hideNavbar(navbar) );
        searchbar.addEventListener('focusout', () => showNavbar(navbar) );
    }
};

export { listenSearchbar };
