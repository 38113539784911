const initStyleInputs = (radio_inputs) => {
  radio_inputs.forEach((input) => {
    input.style.backgroundColor = "white";
    input.style.color = "black";
  });
};

const changeStyle = (event, radio_inputs) => {
  initStyleInputs(radio_inputs);
  event.currentTarget.firstElementChild.checked = true;
  event.currentTarget.style.backgroundColor = "#6FCF97";
  event.currentTarget.querySelector('input').classList.add = "text-white";
};

const listenInputs = () => {
  const radio_inputs = document.querySelectorAll('.radio_buttons .form-check');
  if (radio_inputs !== []) {
    radio_inputs.forEach((input) => {
      if (input.firstElementChild.checked === true) {
        input.style.backgroundColor = "#6FCF97";
        input.style.color = "#ffffff";
      };
      input.addEventListener('click', (event) => {
        changeStyle(event, radio_inputs);
      });
    });
  }; 
};

export {listenInputs};
