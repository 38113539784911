import { Controller } from "stimulus";
import { regexFromFormat } from '../components/regex_from_format'

export default class extends Controller {
  static targets = ["input"]
  submit() {
    let regex = /(\d{6}){1}/g;
    if (this.inputTarget.dataset.format != null) {
      regex = regexFromFormat(this.inputTarget.dataset.format)
    }
    const valid = this.inputTarget.value.match(regex);
    if (valid !== null && valid.length == 1) {
      this.inputTarget.form.requestSubmit()
    }
  }
}

