import { Controller } from "stimulus"
import TomSelect from "tom-select";

export default class extends Controller {

  static targets = ["input", "addItem", "template", "form", "conditionField", "tsControl", "submitEan", "totalAmount"]

  connect() {
    this.initTomSelectInput();
    this.observeTsControl();
  }

  initTomSelectInput() {
    const inputTarget = this.inputTarget
    const currentController = this
    this.eanInput = new TomSelect(inputTarget, {
      persist: false,
      maxItems: null,
      duplicates: true,
      selectOnTab: true,
      openOnFocus: false,
      create: true,
      render: {
        option_create: function (data, escape) {
          return '<div class="create">Ajouter cet EAN <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: function (data, escape) {
          return '<div class="no-results"></div>';
        },

        dropdown: function () {
          return '<div></div>';
        },
        item: function (item, escape) {
          return `<div data-rp-id="">${item.text}</div>`;
        }
      },
      onInitialize: function () {
        const tsControl = this.control;
        tsControl.dataset.scanGroupBasketLineTarget = "tsControl";
      },
      onType: function (str) {

        let regex = /(\d{13})/g;
        const valid = str.match(regex);
        if (valid !== null && valid.length == 1) {
          this.createItem(str, false)
        }
      },

    })
  }

  observeTsControl() {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          this.send();
        }
      }
    });

    observer.observe(this.tsControlTarget, { childList: true });
  }

  // onSubmitButtonClick(event) {
  //   event.preventDefault();
  //   this.send();
  // }
  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
    this.eanInput.removeItem(item.querySelector('.product-ean').textContent)
  }
  send() {
    console.log("start js")
    const url = this.formTarget.action + "/?product%5Bean%5D=" + this.inputTarget.value
    fetch(url, {
      method: "GET",
      headers: { "Accept": "application/json", "X-CSRF-Token": this.csrfToken },
    })
      .then(response => response.json())
      .then((data) => {
        console.log("get data")
        if (data.message) {
          document.querySelector("#flash").outerHTML = data.message
        } else {
          const timestamp = new Date().valueOf()
          var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, timestamp)
          this.addItemTarget.insertAdjacentHTML('beforebegin', content)
          const newItem = this.element.querySelector(`.nested-fields input[id*="${timestamp}"]`).closest('.nested-fields')

          const purchaseStatus = data.purchase_status ? "OK" : "NOK"
          newItem.querySelector('.product-image').querySelector('img').src = data.image_url
          newItem.querySelector('.product-info').querySelector('input').value = data.product_id
          newItem.querySelector('.product-expected-price').querySelector('input').value = data.price
          newItem.querySelector('.product-ean').innerHTML = data.ean
          newItem.querySelector('.product-author').innerHTML = `auteur : ${data.author}`
          newItem.querySelector('.product-title').innerHTML = data.title
          newItem.querySelector('.product-release-date').innerHTML = data.release_date_year
          newItem.querySelector('.product-price').innerHTML = `${data.price.replace('.', ',')}`
          newItem.querySelector('.product-purchase-status').innerHTML = `<span class='label-purchase label-purchase-${data.purchase_status}'>${purchaseStatus}</span>`
          var totalPriceCents = 0
          document.querySelectorAll(".product-price").forEach((blPrice)=>{
            var value = parseFloat(blPrice.textContent.replace(',', '.'));
            totalPriceCents += value * 100;
          })
          this.totalAmountTarget.innerHTML = (parseInt(totalPriceCents) / 100.0).toFixed(2).replace('.', ',')
        }
        const scrollableDiv = document.querySelector("div.container-form-results")
        scrollableDiv.scrollTo({
              top: scrollableDiv.scrollHeight,
              behavior: 'smooth'
        });
      })
  }
}
