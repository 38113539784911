export function regexFromFormat(format) {
  let regex = null
  console.log(format)
  if (format == "ean") {
    regex = /(\d{13})/g;
  } else if (format == "location") {
    regex = /A\d{9}/g;
  }
  return regex
}
