import { submitForm } from "./submit_form"

const submitReceivedBooksInput = () => {
  const inputReceivedBook = document.getElementById("received_product_id");
  if(inputReceivedBook){
    submitForm(inputReceivedBook);
  }
}

export { submitReceivedBooksInput };
