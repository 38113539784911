import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submit", "input"]

  connect() {
    this.setDisable()
  }
  setDisable() {
    if (this.inputTarget.checked) {
      this.submitTarget.classList.remove("disabled")
    } else {
      this.submitTarget.classList.add("disabled")
    }
  }
}
