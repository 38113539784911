let flag = true;

const openPopup = () => {
    if(flag) {
        $('#popupEmailInfo').modal();
        flag = false;
    }
};

const popUpEmailInformation = () => {
    const emailField = document.getElementById('new-customer-email');

    if (emailField) {
        emailField.addEventListener('click', openPopup );
    }
};


export { popUpEmailInformation };